import vendorlist from '../../../assets/icons/Vendorlist.png'

const vendors_data = [

   {
      id:1,
      image: vendorlist, 
      name: "Pre Plates",
      rating: "5.0",
      tag : " Plates",
   },

   {
      id:2,
      image: vendorlist, 
      name: "Preshu Plates",
      rating: "4.0",
      tag : "Name Plates",
   },

   {
      id:3,
      image: vendorlist, 
      name: "Preshu Plates",
      rating: "4.0",
      tag : "Name Plates",
   },

   {
      id:4,
      image: vendorlist, 
      name: "Preshu Plates",
      rating: "4.0",
      tag : "Name Plates",
   },

   {
      id:5,
      image: vendorlist, 
      name: "Preshu Plates",
      rating: "4.0",
      tag : "Name Plates",
   },

   {
      id:6,
      image: vendorlist, 
      name: "Preshu Plates",
      rating: "4.0",
      tag : "Name Plates",
   },

   {
      id:7,
      image: vendorlist, 
      name: "Preshu Plates",
      rating: "4.0",
      tag : "Name Plates",
   },
   {
      id:8,
      image: vendorlist, 
      name: "Preshu Plates",
      rating: "4.0",
      tag : "Name Plates",
   },

   {
      id:9,
      image: vendorlist, 
      name: "Preshu Plates",
      rating: "4.0",
      tag : "Name Plates",
   },

   {
      id:10,
      image: vendorlist, 
      name: "Preshu Plates",
      rating: "4.0",
      tag : "Name Plates",
   },

   {
      id:11,
      image: vendorlist, 
      name: "Preshu Plates",
      rating: "4.0",
      tag : "Name Plates",
   },

   {
      id:12,
      image: vendorlist, 
      name: "Preshu Plates",
      rating: "4.0",
      tag : "Name Plates",
   },

   {
      id:13,
      image: vendorlist, 
      name: "Preshu Plates",
      rating: "4.0",
      tag : "Name Plates",
   },

   {
      id:14,
      image: vendorlist, 
      name: "Preshu Plates",
      rating: "4.0",
      tag : "Name Plates",
   },
]

export default vendors_data;